import { useState, useEffect } from 'react';
import axios from 'axios';

export default function LeaderboardPage() {
    const [users, setUsers] = useState([]);
    const [sortOrder, setSortOrder] = useState('desc'); // or 'asc'
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        async function fetchLeaderboard() {
            try {
                const response = await axios.get('https://group40-backend-6f5x6b5g2q-ez.a.run.app/leaderboard');
                setUsers(response.data);
            } catch (error) {
                console.error('Error fetching leaderboard:', error);
            }
        }
        fetchLeaderboard();
    }, []);

    function handleSearch(event) {
        setSearchTerm(event.target.value);
    }

    function handleSort() {
        setSortOrder((prevSortOrder) => (prevSortOrder === 'asc' ? 'desc' : 'asc'));
    }

    const filteredUsers = users.filter(user =>
        user.username.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const sortedUsers = filteredUsers.sort((a, b) => {
        if (sortOrder === 'asc') {
            return a.score - b.score;
        } else {
            return b.score - a.score;
        }
    });

    return (
        <div className="container mx-auto p-4">
            <h2 className="text-3xl font-bold mb-4">Leaderboard</h2>

            <div className="flex gap-2 has-[input:focus]:ring my-4 p-2 rounded-full bg-white dark:bg-zinc-800 overflow-clip">
                <input
                    type="text"
                    placeholder="Search for users"
                    value={searchTerm}
                    onChange={handleSearch}
                    className="grow px-3 outline-none bg-transparent"
                />
                <button onClick={handleSort} className="bg-black text-white dark:bg-zinc-50 dark:text-black flex py-2 hover:text-blue-400 px-5 rounded-full">
                    Sort by Score ({sortOrder === 'asc' ? 'Ascending' : 'Descending'})
                </button>
            </div>

            <table className="flex flex-col min-w-full bg-white dark:bg-zinc-800 dark:text-zinc-50 rounded-xl">
                <thead>
                    <tr className='flex justify-between border-b w-full'>
                        <th className="py-2 px-4 text-left">Name</th>
                        <th className="py-2 px-4 text-left">Best Score</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedUsers.map((user) => (
                        <tr key={user.username} className="flex justify-between border-b last:border-b-0 w-full">
                            <td className="py-2 px-4 hover:underline truncate">{user.username}</td>
                            <td className="py-2 px-4">{user.score}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import GamePage from './GamePage';
import StatsPage from './StatsPage';
import LeaderboardPage from './LeaderboardPage';
import "./index.css";
import { Toaster } from 'sonner';

function App() {
  const navigation = [
    { "path": "/", "label": "Home" },
    { "path": "/leaderboard", "label": "Leaderboard" }
  ];

  return (
    <>
    <Router>
      <header>
        <ul className='max-w-4xl mx-auto p-6 flex justify-center gap-4'>
          {navigation.map(item => (
            <li key={item.path}>
              <Link to={item.path} className="px-6 py-3 rounded-full text-xl bg-zinc-200  hover:bg-blue-500/20 hover:text-blue-500">{item.label}</Link>
            </li>
          ))}
        </ul>
      </header>

      <div className="App p-6 max-w-4xl mx-auto">
        <Routes>
          <Route path="/" element={<GamePage />} />
          <Route path="/stats" element={<StatsPage />} />
          <Route path="/leaderboard" element={<LeaderboardPage />} />
        </Routes>
      </div>
    </Router>
    <Toaster richColors/>
    </>
  );
}

export default App;

import { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { toast } from "sonner";
axios.defaults.withCredentials = true;

export default function GamePage() {
  const [guess, setGuess] = useState('');
  const [response, setResponse] = useState('');
  const [prompt, setPrompt] = useState('');
  const [guessedPrompts, setGuessedPrompts] = useState([]);
  const [sortedGuessedPrompts, setSortedGuessedPrompts] = useState([]);
  const [buttonText, setButtonText] = useState('Reveal Answer');
  const [revealedPrompt, setRevealedPrompt] = useState('');
  const [showButtons, setShowButtons] = useState(true);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState(''); // New state for password
  const [showLoginForm, setShowLoginForm] = useState(false); // New state for login form visibility
  const [hearts, setHearts] = useState(3); // State for hearts
  const hasStartedGame = useRef(false); // Ref to track if the game has started
  const baseRed = '#ff6666'; // Slightly more vibrant red
  const baseBlue = '#6666ff'; // Slightly more vibrant blue
  const biasFactor = 0.2; // Adjust this factor to control the bias strength

  // Calculate the background color based on score
  const calculateBackgroundColor = useCallback((score) => {
    const calculateBiasedColor = (score) => {
      const clampedScore = Math.max(0, Math.min(100, score));
      const mixPercentage = clampedScore * (1 - biasFactor) + (clampedScore > 50 ? biasFactor * 100 : 0);
      return `color-mix(in oklab, ${baseRed}, ${baseBlue} ${mixPercentage}%)`;
    };
    return calculateBiasedColor(score);
  }, [baseRed, baseBlue, biasFactor]);

  // Reload response and reset states
  const reloadResponse = useCallback(() => {
    setResponse('');
    setButtonText('Reveal Answer');
    setRevealedPrompt(''); // Clear the revealed prompt first
    hasStartedGame.current = false;
    setGuessedPrompts([]);
    setSortedGuessedPrompts([]);
    startGame(); // Start a new game
    document.body.style.backgroundColor = "rgb(244 244 245)";
  }, []);

  // Handle button click to reveal answer or generate new prompt
  const handleButtonClick = useCallback(() => {
    if (buttonText === 'Reveal Answer') {
      setRevealedPrompt(prompt);
      setButtonText('Generate New Prompt');
      const highestScore = sortedGuessedPrompts[0]?.score || 0;
      updateLeaderboard(username || 'User', highestScore);
    } else {
      reloadResponse();
    }
  }, [buttonText, prompt, sortedGuessedPrompts, username, reloadResponse]);

  // Handle the guess submission
  function handleGuess() {
    if (!guess) return;
    axios.post('https://guesstheprompt-backend-6f5x6b5g2q-ez.a.run.app/guess', { guess, prompt }, { withCredentials: true })
      .then(res => {
        const newGuess = res.data;
        const updatedGuessedPrompts = [newGuess, ...guessedPrompts];
        setGuessedPrompts(updatedGuessedPrompts);
        setSortedGuessedPrompts([...updatedGuessedPrompts].sort((a, b) => b.score - a.score));
        setGuess(''); // Clear the input after submitting
        setHearts(hearts - 1); // Decrease hearts by 1
      })
      .catch(error => {
        console.error('Error handling guess:', error);
      });
  };

  // Start the game
  function startGame() {
    if (hasStartedGame.current) return; // Prevent double call
    hasStartedGame.current = true; // Mark as started to prevent re-entry
    axios.get('https://guesstheprompt-backend-6f5x6b5g2q-ez.a.run.app/start_game', { withCredentials: true })
      .then(response => {
        setResponse(response.data.response);
        setPrompt(response.data.prompt);
        localStorage.setItem('response', response.data.response);
        localStorage.setItem('prompt', response.data.prompt);
        setHearts(3); // Reset hearts at the start of the game
      })
      .catch(error => {
        console.error("There was an error!", error);
      });
  }

  // useEffect to start the game on component mount
  useEffect(() => {
    if (!response) {
      startGame();
    }
  }, [response]);

  // useEffect to handle when hearts reach 0
  useEffect(() => {
    if (hearts <= 0 && buttonText !== 'Generate New Prompt') {
      handleButtonClick(); // Simulate button click to reveal the prompt
    }
  }, [hearts, buttonText, handleButtonClick]);

  // useEffect to update background color based on highest score
  useEffect(() => {
    if (sortedGuessedPrompts.length > 0) {
      const highestScore = sortedGuessedPrompts.reduce((max, prompt) => Math.max(max, prompt.score), 0);
      document.body.style.backgroundColor = calculateBackgroundColor(highestScore);
    }
  }, [sortedGuessedPrompts, calculateBackgroundColor]);

  // Update leaderboard with the highest score
  function updateLeaderboard(username, score) {
    axios.post('https://guesstheprompt-backend-6f5x6b5g2q-ez.a.run.app/leaderboard', { score }, { withCredentials: true })
      .then(response => {
        console.log(response.data); // Output the response message (e.g., 'Score updated')
      })
      .catch(error => {
        console.error('Error updating leaderboard:', error);
      });
  }

  // Handle username input change
  const handleNameChange = (e) => {
    setUsername(e.target.value);
  };

  // Handle password input change
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  // Handle login form submission
  const handleLoginSubmit = (e) => {
    e.preventDefault();
    axios.post('https://guesstheprompt-backend-6f5x6b5g2q-ez.a.run.app/login', { username, password }, {
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true // Ensure credentials are included
    })
      .then(res => {
        if (res.status === 200 || res.status === 201) {
          setShowLoginForm(false);
          setShowButtons(false);
          toast.success(res.data.message);
        }
      })
      .catch(error => {
        console.log(error);
        toast.error('Invalid credentials');
      });
  };

  // Show login form
  const handleLoginClick = () => {
    setShowLoginForm(true);
  };

  // Handle anonymous login
  const handleAnonClick = () => {
    setUsername('User');
    setPassword(''); // Clear the password
    setShowLoginForm(false); // Hide the login form
    setShowButtons(false);
  };

  return (
    <>
      <div className='text-center mt-16 mb-8'>
        <h1 className='text-7xl font-extrabold' style={{ fontFamily: 'Arial, sans-serif' }}>Guess the Prompt</h1>
        <p className='mt-4' style={{ fontSize: '1.475rem' }}>
          Think you can reverse-engineer the prompt? Try your best guess!
        </p>
        {showButtons && (
          <div className="flex justify-center gap-4 mt-4">
            <button onClick={handleLoginClick} className="text-xl text-white bg-black hover:bg-gray-800 px-4 py-2">
              Login
            </button>
            <button onClick={handleAnonClick} className="text-xl text-white bg-black hover:bg-gray-800 px-4 py-2">
              Anon
            </button>
          </div>
        )}
      </div>
      {showLoginForm && (
        <form onSubmit={handleLoginSubmit} className="flex flex-col items-center gap-4 mt-4">
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={handleNameChange}
            className="px-4 py-2 border rounded"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={handlePasswordChange}
            className="px-4 py-2 border rounded"
          />
          <button type="submit" className="text-xl text-white bg-black hover:bg-gray-800 px-4 py-2">
            Submit
          </button>
        </form>
      )}
      {!showLoginForm && !showButtons && (
        <>
          <div className='mt-10 mb-16'>
            <div className="text-black p-4 pb-0 text-center rounded-lg" style={{ height: '160px', overflow: 'hidden' }}>
              <p className="text-2xl">
                {response ? response : "Loading..."}
              </p>
              {revealedPrompt && <p className="text-xl mt-4">Prompt: {revealedPrompt}</p>}
            </div>
            <div className="flex justify-center">
              <button onClick={handleButtonClick} className="rainbow-button text-xl w-full text-white px-2 py-1">
                {buttonText}
              </button>
            </div>
          </div>
          <div className="flex justify-center my-4 hearts-container">
            {[...Array(hearts)].map((_, index) => (
              <span key={index} style={{ color: 'red', fontSize: '2rem' }}>❤️</span>
            ))}
          </div>
          <form onSubmit={(event) => {
            event.preventDefault();
            handleGuess();
          }}
            className='flex gap-2 has-[input:focus]:ring my-4 p-2 rounded-full bg-white overflow-clip'
            >
            <input
              type="text"
              value={guess}
              onChange={(e) => setGuess(e.target.value)}
              className='grow px-3 outline-none bg-transparent'
              placeholder="Enter your guess human!"
              disabled={hearts <= 0} // Disable input when hearts reach 0
            />
            <input type='submit' value="Submit" className='bg-black text-white flex py-2 hover:text-blue-400 px-5 rounded-full' disabled={hearts <= 0} /> {/* Also disable the submit button */}
          </form>
          <div style={{ height: '300px', overflowY: 'auto' }}>
            {guessedPrompts.length > 0 && (
              <>
                <div className='flex my-8 px-3 gap-4 justify-between items-center'>
                  <p className='text-2xl font-medium'>{guessedPrompts[0].guess}</p>
                  <div style={{ backgroundColor: calculateBackgroundColor(guessedPrompts[0].score) }} className="text-white size-9 grid shrink-0 place-items-center rounded-full">
                    {guessedPrompts[0].score}
                  </div>
                </div>
                <hr className='my-6 border-zinc-500 opacity-20' />
                {sortedGuessedPrompts.map((guessedPrompt) => (
                  <div key={guessedPrompt.guess} className='flex gap-2 px-3 py-2 rounded-xl bg-white mb-2 justify-between items-center'>
                    <p>{guessedPrompt.guess}</p>
                    <div style={{ backgroundColor: calculateBackgroundColor(guessedPrompt.score) }} className="text-white size-9 shrink-0 grid place-items-center rounded-full">
                      {guessedPrompt.score}
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </>
      )}
      <style jsx>{`
        .rainbow-button {
          background: linear-gradient(90deg, #ff9a9e, #fad0c4, #fad0c4, #fbc2eb, #a18cd1, #fbc2eb, #fad0c4, #ff9a9e);
          background-size: 200% 200%;
          animation: rainbow 20s ease infinite;
          border: none;
          border-radius: 8px;
          padding: 10px 20px;
          color: white;
          font-weight: bold;
          cursor: pointer;
          transition: transform 0.2s;
        }
        .rainbow-button:hover {
          transform: scale(1.05);
        }
        @keyframes rainbow {
          0% { background-position: 0% 50%; }
          50% { background-position: 100% 50%; }
          100% { background-position: 0% 50%; }
        }
      `}</style>
    </>
  );
}
